import { queryStringBuilder } from './helper';
const baseurlIdentity = process.env.REACT_APP_IDENTITY_ENDPOINT_BASE_URL;
const baseurlcore = process.env.REACT_APP_CORE_ENDPOINT_BASE_URL;
const bucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

const EndPointConfig = {
  UserOnboarding: {
    postPasswordLessChanllenge: `${baseurlIdentity}/api/account/auth/signin/challenge`,
    validateOTP: `${baseurlIdentity}/api/account/auth/passwordless/v2`,
    resendOTP: `${baseurlIdentity}/api/account/auth/resendotp`,
    token: `${baseurlIdentity}/api/account/token/v2`,
    newRefreshToken: `${baseurlIdentity}/api/account/refreshtoken/v2`,
    getSwrIdCard: `${baseurlcore}/api/vcard/verify/swr`,
    getIRCTCIdCard: `${baseurlcore}/api/vcard/verify/irctc`,
    getOTPForForm: `${baseurlIdentity}/api/account/datatype/otp`,
  },
  Teams: {
    getMyTeams: (userId: string) =>
      `${baseurlIdentity}/api/team/me?user_id=${userId}`,
    getTeamUserDetails: (teamId: string, userId: string) =>
      `${baseurlIdentity}/api/team/member/details?team_id=${teamId}&user_id=${userId}`,
    getUserDesignationDetails: (designationId: string) =>
      `${baseurlIdentity}/api/designation/${designationId}`,
    getUserDirectoryDesignation: (config: string) =>
      `${baseurlIdentity}/api/designation/me/directory?config_type=${config}`,
    getTeamDesignations: (teamId: string) =>
      `${baseurlIdentity}/api/designation/query?team_id=${teamId}`,
  },
  Reports: {
    getAllMyReports: `${baseurlcore}/api/formtemplate`,
    getInboxInstanceIds: (
      templateId: string,
      query: any,
      filterQuery: string
    ) => {
      let queryString = '';
      if (filterQuery && filterQuery.trim().length > 0)
        queryString = `${queryStringBuilder(query)}&${filterQuery}`;
      else queryString = `${queryStringBuilder(query)}`;
      return `${baseurlcore}/api/formdata/me/inbox/sync/templateid/${templateId}?${queryString}`;
    },
    getInboxData: (templateId: string, instanceIds: any) => {
      return `${baseurlcore}/api/formdata/inboxview/query/default/templateid/${templateId}?_mo_instance_id=${instanceIds}`;
    },
    getUnassignedData: (
      templateId: string,
      queryString: any,
      stageName: string,
      offset: any,
      pageSize: any
    ) =>
      `${baseurlcore}/api/formdata/v2/me/inbox/unassigned/templateid/${templateId}?_mo_stage=${stageName}&offset=${offset}&limit=${pageSize}&${queryString}`,

    getReportDetails: (templateId: string, teamId: string) =>
      `${baseurlcore}/api/formtemplate/query?_id=${templateId}&team_id=${teamId}`,
    getFormDataDetails: (templateId: string, instanceId: string) =>
      `${baseurlcore}/api/formdata/templateid/${templateId}/instanceid/${instanceId}`,
    getRatingFormDataDetails: (templateId: string, instanceId: string) =>
      `${baseurlcore}/api/formdata/rating/templateid/${templateId}/instanceid/${instanceId}`,
    createFormData: (templateId: string) =>
      `${baseurlcore}/api/formdata/v2/templateid/${templateId}`,
    createVendorOnboardingForm: `${baseurlcore}/api/custom/railway/vendorid`,
    updateFormData: (templateId: string, instanceId: string) =>
      `${baseurlcore}/api/formdata/v2/templateid/${templateId}/instanceid/${instanceId}`,
    getLookupInstanceIds: (
      sourceTemplateId: string,
      referenceTemplateId: string,
      fieldIntenalName: string,
      queryString: any
    ) => {
      const query = `?source_tid=${sourceTemplateId}&field_internal_name=${fieldIntenalName}&${queryString}`;
      return `${baseurlcore}/api/formdata/sync/templateid/${referenceTemplateId}${query}`;
    },
    getExternalLookupData: (templateId: string, instanceId: string) =>
      `${baseurlcore}/api/formdata/query/templateid/${templateId}?_mo_instance_id=${instanceId}`,
    getCatalogData: (details: any) => {
      if (details.queryString === '') {
        return `${baseurlcore}/api/formdata/list/templateid/${details.templateId}?limit=${details.limit}&offset=${details.offset}`;
      } else {
        return `${baseurlcore}/api/formdata/list/templateid/${details.templateId}?limit=${details.limit}&offset=${details.offset}&${details.queryString}`;
      }
    },
    updateAssignee: (templateId: string) =>
      `${baseurlcore}/api/formdata/assignment/me/templateid/${templateId}`,
    getSignedUrl: (filePath: any, contentType: any, action = 'read') => {
      const query = `action=${action}&bucket=${bucket}&filepath=${filePath}&contentType=${contentType}`;
      return `${baseurlcore}/api/formdata/getsignedurl?${query}`;
    },
    getAllInboxConfigs: (templateId: string) =>
      `${baseurlcore}/api/formtemplate/inboxconfig/query/templateid/${templateId}`,
    getInboxViewsData: (templateId: string, viewName: string) =>
      `${baseurlcore}/api/formdata/inboxview/query/view/${viewName}/templateid/${templateId}`,
    getActivityLog: (templateId: string, instanceId: string) =>
      `${baseurlcore}/api/formdataactivitylog/templateid/${templateId}/instanceid/${instanceId}`,
    getNeProductFilter: (templateId: string, filterQuery: string) => {
      return `${baseurlcore}/api/formdata/product/query/filter/templateid/${templateId}?${filterQuery}`;
    },
  },
  guestUser: {
    token: `${baseurlIdentity}/api/guestuser/public/token`,
    getOtp: `${baseurlIdentity}/api/guestuser/auth/signin/challenge`,
    validateGuestUserOTP: `${baseurlIdentity}/api/account/auth/passwordless/v2`,
    resendOTP: `${baseurlIdentity}/api/account/auth/resendotp`,
    authorizedToken: `${baseurlIdentity}/api/guestuser/auth/token`,
    accessToken: `${baseurlIdentity}/api/guestuser/captcha/auth/token`,
    order: `${baseurlcore}/api/paymentgateway/naveenent/order`,
    paymentConfirm: `${baseurlcore}/api/paymentgateway/naveenent/payment/confirm`,
    getTeamDetails: (teamId: string) =>
      `${baseurlIdentity}/api/team/queryOne?team_id=${teamId}`,
    getTeamSignedUrl: (filepath: string, contentType: string) =>
      `${baseurlIdentity}/api/team/getsignedurl?action=read&bucket=${bucket}&filepath=${filepath}&contentType=${contentType}`,
    irctcOrder: `${baseurlcore}/api/paymentgateway/irctc/order`,
    irctcPaymentConfirm: `${baseurlcore}/api/paymentgateway/irctc/payment/confirm`,
    getIrctcCardList: (desigId: string, templateId: string) =>
      `${baseurlcore}/api/paymentgateway/irctc/idcard/list/${desigId}/templateid/${templateId}`,
  },
};

export default EndPointConfig;
